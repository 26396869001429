import '../index.scss';
import Image1 from '../../../assets/images/floral-shop-2.jpg';
import Image1Small from '../../../assets/images/floral-shop-2b.jpg';
import Image2 from '../../../assets/images/floral-shop-3.jpg';
import Image2Small from '../../../assets/images/floral-shop-3b.jpg';
import Image3 from '../../../assets/images/floral-shop-4.jpg';
import Image3Small from '../../../assets/images/floral-shop-4b.jpg';
import Image4 from '../../../assets/images/floral-shop-5.jpg';
import Image4Small from '../../../assets/images/floral-shop-5b.jpg';
import GitHub from '../../../assets/images/github-icon.png';


const Project1 = () => {

  return (
    <div className='container project-page' role="main">
        <h1>Floral Shop</h1>
      <div className='project-content'>
        <div className='project-photo'>
        <img 
          className='project-photo-img' 
          srcSet={`${Image1Small} 288w, ${Image1} 720w`} 
          sizes="(max-width: 672px) 288px, (max-width: 1300px) 400px, 720px"
          src={Image1} 
          alt="The main page of the Floral Shop" 
        />
        </div>
        <p>The Flower Shop is an e-commerce website for a Vancouver-based florist. </p>
        <div className='project-photo'>
          <img 
            className='project-photo-img' 
            srcSet={`${Image2Small} 288w, ${Image2} 720w`} 
            sizes="(max-width: 672px) 288px, (max-width: 1300px) 400px, 720px"
            src={Image2} 
            alt="A product page" 
          />
          <p>A product page</p>
        </div>
        <p>There are different pages to learn more about the company, buy a monthly flower subscription, or request a quote for an event. There is a fully featured shop that allows customers to pay online and pick up their order or have it delivered within a certain area.</p>
        <div className='project-photo'>
          <img 
            className='project-photo-img' 
            srcSet={`${Image3Small} 256w, ${Image3} 378w`} 
            sizes="(max-width: 672px) 256px, 378px"
            src={Image3} 
            alt="Mobile wireframes for the Floral Shop" 
          />
          <p>The home page in mobile view</p>
        </div>
        <p>The site was built in Wordpress with the Woocommerce plugin and many, many other plugins like Gravity Forms and WP Go Maps.</p>
        <div className='project-photo'>
          <img 
            className='project-photo-img' 
            srcSet={`${Image4Small} 288w, ${Image4} 720w`} 
            sizes="(max-width: 672px) 288px, (max-width: 1300px) 400px, 720px"
            src={Image4} 
            alt="Mobile wireframes for the Floral Shop" 
          />
          <p>Mobile wireframes for the Floral Shop</p>
        </div>
        <p>The site was designed in Adobe XD, and Canva’s AI image-generator was used to create the product images. Along with the CMS’ back end, I wrote PHP and SASS code to bring it to life. I also created the site’s logo in Adobe Illustrator.</p>
        <div className='github-link'>
          <h2>View this project on Github</h2>
          <a href="https://github.com/htpwebdesign/floral-shop"><img src={GitHub} alt="GitHub" /></a>
        </div>
      </div>
    </div>
  );

}

export default Project1;