import { Route, Routes } from 'react-router-dom';
import './App.scss';
import Layout from './components/Layout';
import About from './components/About';
import Work from './components/Work';
import Landing from './components/Landing';
import NotFound from './components/NotFound';
import Project1 from './components/Project/Project1';
import Project2 from './components/Project/Project2';
import Project3 from './components/Project/Project3';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/main" element={<Layout />}>
          <Route index element={<About />} />
          <Route path="about" element={<About />} />
          <Route path="work" element={<Work />} />
          <Route path="floral-shop" element={<Project1 />} />
          <Route path="movie-database" element={<Project2 />} />
          <Route path="portfolio" element={<Project3 />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;