import './index.scss';
import AboutPhoto from '../../assets/images/about-photo.jpg';
import AboutPhotoSmall from '../../assets/images/about-photob.jpg';
import LinkedIn from '../../assets/images/linkedin-icon.png';
import GitHub from '../../assets/images/github-icon.png';
import Email from '../../assets/images/email-icon.png';

const About = () => {

  return (
    <div className='container about-page' role="main">
      <div className='about-content'>
        <div className='about-photo'>
          <img 
            className='project-photo-img' 
            srcSet={`${AboutPhotoSmall} 150w, ${AboutPhoto} 300w`} 
            sizes="(max-width: 320px) 150px, 300px"
            src={AboutPhoto} 
            alt="Gray Black, themselves" 
          />
        </div>
        <h1>Yes, Gray</h1>
        <article className='about-text'>
          <p>Like the colour.</p> 
          
          <p>I'm excited to be entering the Web Development space as I start a new chapter in my life. My experiences range from creative and collaborative work, service industry positions, volunteering and more but I am looking forward to a career in an industry that values analytical thinking paired with problem solving skills.</p>

          <p>This is a portfolio website that I made for a school project, but I also look forward to using this site as a place to continue testing and bettering my development skills while I look for the right opportunity! Take a look around to see what I've been working on.</p>

          <p>Please feel free to reach out via LinkedIn! I'm looking to grow my network. I love working with other strong communicators with unique ideas but I can also take on complex tasks alone as I am great at challenging my own perspective and pushing the boundaries.</p>
        </article>
      </div>
      <div className='contact-heading'>
        <h2>Get in Touch</h2>
      </div>
      <div className='contact-icons'>
        <a href="https://www.linkedin.com/in/theyaregrayblack"><img src={LinkedIn} alt="LinkedIn" /></a>
        <a href="https://github.com/theyaregray/"><img src={GitHub} alt="GitHub" /></a>
        <a href="mailto:gray@theyaregray.black"><img src={Email} alt="Email" /></a>
      </div>
    </div>
  );

}

export default About;