import { Outlet, useLocation } from 'react-router-dom';
import Titlecard from '../Titlecard';
import './index.scss';

const Layout = () => {
  const location = useLocation();
  const showTitlecard = location.pathname !== '/';

  return (
    <div className='App'>
      {showTitlecard && <Titlecard />}
      <div className='page'>
        <Outlet />
      </div>
    </div>
  )
}

export default Layout;