import '../index.scss';
import Image1 from '../../../assets/images/portfolio-site-1.jpg';
import Image1Small from '../../../assets/images/portfolio-site-1b.jpg';
import Image2 from '../../../assets/images/portfolio-site-2.jpg';
import Image2Small from '../../../assets/images/portfolio-site-2b.jpg';
import Image3 from '../../../assets/images/portfolio-site-3.jpg';
import Image3Small from '../../../assets/images/portfolio-site-3b.jpg';
import GitHub from '../../../assets/images/github-icon.png';


const Project3 = () => {

  return (
    <div className='container project-page' role="main">
        <h1>Portfolio Site</h1>
      <div className='project-content'>
        <div className='project-photo'>
          <img 
            className='project-photo-img' 
            srcSet={`${Image1Small} 288w, ${Image1} 720w`} 
            sizes="(max-width: 672px) 288px, (max-width: 1300px) 400px, 720px"
            src={Image1} 
            alt="The landing page of this portfolio" 
          />
        </div>
        <p>Hey, wait a minute! This is this site! I built this portfolio in order to showcase my projects and talk a little bit about myself in order to get a job. Got any of those lying around?</p>
        <div className='project-photo'>
          <img 
            className='project-photo-img' 
            srcSet={`${Image2Small} 288w, ${Image2} 720w`} 
            sizes="(max-width: 672px) 288px, (max-width: 1300px) 400px, 720px"
            src={Image2} 
            alt="Design wireframes" 
          />
          <p>Design wireframes</p>
        </div>
        <p>I’m proud of my design and the fun colours I put together. My reach exceeded my grasp with this project and I wasn’t able to build the dynamically generated project pages I wanted to. Ah well, something for Version 2.0!</p>
        <div className='project-photo'>
          <img 
            className='project-photo-img' 
            srcSet={`${Image3Small} 256w, ${Image3} 378w`} 
            sizes="(max-width: 672px) 256px, 378px"
            src={Image3} 
            alt="The main page in mobile view" 
          />
          <p>The main page in mobile view</p>
        </div>
        <p>I designed this site in Adobe XD and used Adobe Photoshop to prepare the images. The site itself was written in React.js.</p>
      </div>
      <div className='github-link'>
          <h2>View this project on Github</h2>
          <a href="https://github.com/theyaregray/"><img src={GitHub} alt="GitHub" /></a>
      </div>
    </div>
  );

}

export default Project3;