import { Link } from 'react-router-dom';
import './index.scss';

const NotFound = () => (
  <>
  <div className='not-found-content' role="main">
    <h1>404 Not Found</h1>
    <Link className='not-found-link' to="/main">Return to the site</Link>
  </div>
  </>
);

export default NotFound;