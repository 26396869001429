import { useEffect, useState } from "react";
import './RenderTimer.scss';

const RenderTimer = () => {
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowComponent(true);
    }, 1600);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`render-timer ${showComponent ? 'show' : ''}`}>
      Click to Enter
    </div>
  );
};

export default RenderTimer;