import '../index.scss';
import Image1 from '../../../assets/images/movie-db-1.jpg';
import Image1Small from '../../../assets/images/movie-db-1b.jpg';
import Image2 from '../../../assets/images/movie-db-2.jpg';
import Image2Small from '../../../assets/images/movie-db-2b.jpg';
import Image3 from '../../../assets/images/movie-db-3.jpg';
import Image3Small from '../../../assets/images/movie-db-3b.jpg';
import GitHub from '../../../assets/images/github-icon.png';


const Project2 = () => {

  return (
    <div className='container project-page' role="main">
        <h1>Movie Database</h1>
      <div className='project-content'>
        <div className='project-photo'>
          <img 
            className='project-photo-img' 
            srcSet={`${Image1Small} 288w, ${Image1} 720w`} 
            sizes="(max-width: 672px) 288px, (max-width: 1300px) 400px, 720px"
            src={Image1} 
            alt="The main page of OurMovieDB" 
          />
        </div>
        <p>OurMovieDB is a React site that serves a database of thousands of film titles pulled from the TMDB API.</p>
        <div className='project-photo'>
          <img 
            className='project-photo-img' 
            srcSet={`${Image2Small} 256w, ${Image2} 378w`} 
            sizes="(max-width: 672px) 256px, 378px"
            src={Image2} 
            alt="The OurMovieDB home page in mobile view" 
          />
          <p>The OurMovieDB home page in mobile view</p>
        </div>
        <p>My team and I had to coordinate carefully using GitHub and Slack to ensure we worked efficiently and without code conflicts. We were able to create thrilling features like a favouriting system which marks movie posters with a red heart.</p>
        <div className='project-photo'>
          <img 
            className='project-photo-img' 
            srcSet={`${Image3Small} 288w, ${Image3} 720w`} 
            sizes="(max-width: 672px) 288px, (max-width: 1300px) 400px, 720px"
            src={Image3} 
            alt="The OurMovieDB favourites page" 
          />
          <p>The OurMovieDB favourites page</p>
        </div>
        <p>OMDB was built with React.js and the TMDB API. </p>
      </div>
      <div className='github-link'>
          <h2>View this project on Github</h2>
          <a href="https://github.com/NandoHsp/ourmoviedb-app"><img src={GitHub} alt="GitHub" /></a>
      </div>
    </div>
  );

}

export default Project2;