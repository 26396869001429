import { Link } from 'react-router-dom';
import './index.scss';
import RenderTimer from '../RenderTimer/RenderTimer';

const Landing = () => (
  <Link className='landing-link' to="/main/about">
    <div className='landing-content' role="main">
      <div className='landing-text-1'>
        <h1 className='landing-they-are'>
          <span className='landing-word'>They</span>
          <span className='landing-word'>are</span>
        </h1>
      </div>
      <div className='landing-text-2'>
        <h1 className='landing-gray-black'>
          <span className='landing-word'>Gray</span>
          <span className='landing-word'>Black</span>
        </h1>
      </div>
        <p className='landing-enter'><RenderTimer /></p>
    </div>
  </Link>
);

export default Landing;