import './index.scss';
import { Link } from 'react-router-dom';
import MovieDB from '../../assets/images/movie-db.jpg';
import FloralShop from '../../assets/images/floral-shop.jpg';
import PortfolioSite from '../../assets/images/portfolio-site.jpg';

const Work = () => {

  return (
    <div className='container work-page' role="main">
      <div className='work-content'>
        <h1>Projects</h1>
        <div className='project-grid'>
          <div className='project-item project-floral'>
              <Link className='project-link' to="/main/floral-shop">
                <h2>Floral Shop</h2>
                <div className='project-img-wrapper'>
                  <img src={FloralShop} className='floral-shop-img' alt="Floral Shop" />
                </div>
                <p>Online store built in Wordpress and Woocommerce.</p>
                <div className='learn-more'><p>Learn More...</p></div>
              </Link>
          </div>
          <div className='project-item project-movie'>
              <Link className='project-link' to="/main/movie-database">
                <h2>Movie Database</h2>
                <div className='project-img-wrapper'>
                  <img src={MovieDB} className='movie-db-img' alt="Movie Database" />
                </div>
                <p>React movie site connected to the TMDB API.</p>
                <div className='learn-more'><p>Learn More...</p></div>
              </Link>
          </div>
          <div className='project-item project-portfolio'>
              <Link className='project-link' to="/main/portfolio">
                <h2>Portfolio Site</h2>
                <div className='project-img-wrapper'>
                  <img src={PortfolioSite} className='portfolio-site-img' alt="Portfolio Site" />
                </div>
                <p>A familiar-looking portfolio site, built with React</p>
                <div className='learn-more'><p>Learn More...</p></div>
              </Link>
          </div>
        </div>
      </div>
    </div>
  );

}

export default Work;