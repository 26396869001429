import { Link, NavLink } from 'react-router-dom';
import './index.scss';

const Titlecard = () => (
  <div className='nav-bar' role="navigation">
    <div className='titlecard-wrapper'>
      <Link className='name-title' to='/'>
        <h1 className='name-gray-black'>
          Gray Black
        </h1>
      </Link>
      <p className='job-title fwd'>Front End Web Developer &</p>
      <p className='job-title ux'>User Experience Designer</p>
      <nav>
        <NavLink exact="true" activeclassname="active" className="about-link" to="about">
          <h2 className='nav-link-heading nav-about'>
            About
          </h2>
        </NavLink>
        <NavLink exact="true" activeclassname="active" className="work-link" to="work">
          <h2 className='nav-link-heading nav-work'>
            Work
          </h2>
        </NavLink>
      </nav>
    </div>
  </div>
);

export default Titlecard;